//const serverUrl: string = '10.234.1.51:8081';
const serverUrl: string = 'api.estado.efactura.st';
// const serverUrl: string = 'api.efactura.st';
// const serverUrl: string = 'localhost:8080';

export const environment = {
  production: false,
  appVersion: "v0.10",
  AUTHORIZATION_KEY: 'ZWZhdHVyYS11aTplZkB0dXJA',

  apiUrl: `https://${serverUrl}`,
  //apiUrl: `http://${serverUrl}`,
  tokenWhitelistedDomains: [new RegExp(serverUrl)],
  tokenBlacklistedRoutes: [new RegExp('/oauth/token')],

  keycloakConfig: {
    url: 'https://homolog.lentla.st/auth',
    realm: 'di-app',
    clientId: 'efactura-test',
  },
  bearerExcludedUrls: [
    '/assets',
    '/clients/public',
    '/oauth/token',
    'https://gateway-stage-pdex.gov.cv/t/nosi.gov/igrp-app-sitiva-getenquadramento/1.0.0/webapps',
  ],
  registerUrl: 'https://homolog.registo.lentla.st',
  sitIvaToken:
    'eyJ4NXQiOiJaamd3WVRKbVlXTTBNakV6WWpFNVkyTXdZamMwTlRnNFlqVTRPRGt3TW1NNE4yUTJNREZqWlRJMFpERm1OekF6T1RJelpqWTBZMlU1WlRRM05XVXpOdyIsImtpZCI6IlpqZ3dZVEptWVdNME1qRXpZakU1WTJNd1lqYzBOVGc0WWpVNE9Ea3dNbU00TjJRMk1ERmpaVEkwWkRGbU56QXpPVEl6WmpZMFkyVTVaVFEzTldVek53X1JTMjU2IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJzZXJ2aWNlLml2YUBub3NpLmN2IiwiYXV0IjoiQVBQTElDQVRJT04iLCJhdWQiOiI4S3Y0clNNbE5XNXpOcXVWaGNZNlRSMFRBSllhIiwibmJmIjoxNjgzODA1MzA5LCJhenAiOiI4S3Y0clNNbE5XNXpOcXVWaGNZNlRSMFRBSllhIiwic2NvcGUiOiJkZWZhdWx0IiwiaXNzIjoiaHR0cHM6XC9cL2xvY2FsaG9zdDo5NDQzXC9vYXV0aDJcL3Rva2VuIiwiZXhwIjo5MjIzMzcyMDM2ODU0Nzc1LCJpYXQiOjE2ODM4MDUzMDksImp0aSI6IjEyZjFlYmU4LTVlM2QtNGJjNS05OGYxLThiNmI3MjUzNWJhNiJ9.l3ZVNkhStNB5u3EVQryl7zF_dcDnyxpyv1zhjjtofpvZv2kXTXlv4bNp-dOQw-sbxD59zQQGHfPSeejuLD7d3LFxEq2n3ccz2zJKfaLZurHQD8w5qjJedL8Rl5YqE8GdSkaVrncSpDXTAvdihydf_4E_D455uUl9rLT3APHfs1yYjq2qlyJOBc0S-aZE9P4OK20tVsyva9gzwCYT-LSxcvZJH53Y9mCkfTgC4wZvzFcKlva0VyOKpuDATLJDMMm1wwbsVDECrGH3DmhGORy7o9cNjvW80QPtjFbDt6Y_KasTWMnkwnNBHXsw2iRrOO4qfVpibRyN-LwuOiaMzdHdHA',
  sitIvaUrl:
    'https://gateway-stage-pdex.gov.cv/t/nosi.gov/igrp-app-sitiva-getenquadramento/1.0.0/webapps',
  fazendaNonActivityUrl: 'https://testes.fazendanon.mf.gov.st/service/finance/activity-declaration/DIA',
  fazendaNonElectronicBoxUrl: 'https://testes.fazendanon.mf.gov.st/service/finance/electronic-mail-box'
};

import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class ManagerGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isUserManager = this.auth.profile?.isManager;
    const isAdminOrAgent =
      this.auth.profile?.rule === "ADMIN" || this.auth.profile?.rule === "AGENTE"
        ? true
        : false;

    if (!isUserManager && !isAdminOrAgent) {
      this.router.navigate(["../"], {
        relativeTo: this.route,
      });
      return false;
    }
    return true;
  }
}

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class ActivationGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const profile = this.auth.profile;

    if (
      profile?.rule === "ADMIN" ||
      profile?.rule === "STATE" ||
      profile?.rule === "AGENTE"
    ) {
      return true;
    } else if (!profile?.isActive) {
      // this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
